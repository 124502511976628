/* eslint-disable vue/no-unused-components */
<template>
  <div class="container-fluid">
    <div class="statistic-box mt-4">
      <ProgrammeBeneficieEditor
        :title="
          activeProgramme && activeProgramme.id
            ? 'Ajouter programme'
            : 'Modifier programme'
        "
        :item="activeProgramme"
        :showAllFields="true"
        ref="programmeBeneficieEditor"
        @save="saveProgrammeItem"
      />
      <!-- <activite-statistic ref="activiteStatistic" class="mt-3 mb-2"/> -->
    </div>
    <div class="container- mt-1">
      <p-progress-bar v-if="mxLoading" style="height: 0.33em" mode="indeterminate" />
      <div class="bg-white p-2 rounded-4">
        <form class="bg-" method="post" accept-charset="utf-8" action="">
          <div class="row py-0 p-fluid grid formgrid">
            <div class="col field">
              <form class="d-flex">
                <div class="input-group me-4">
                  <div class="p-inputgroup">
                    <PInputText
                      type="text"
                      v-model="mxSearch"
                      :placeholder="'Rechercher par nom et/ou prénom'"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div class="col-md-6 col-sm-12 d-flex">
              <div class="input-group me-4">
                <div class="p-inputgroup">
                  <p-dropdown
                    class="flex-grow- me-2"
                    v-model="sexeDe"
                    :options="sexeOptions"
                    optionLabel="label"
                    optionValue="value"
                    :placeholder="'Sexe DE'"
                    display="chip"
                  />
                  <p-dropdown
                    class="flex-grow-1 me-2"
                    v-model="demandeurVulnerabilite"
                    :options="demandeurVulnerabiliteOptions"
                    optionLabel="label"
                    optionValue="value"
                    :placeholder="'Vulnérabilité'"
                    display="chip"
                  />
                  <p-dropdown
                    class="flex-grow-1 me-2"
                    v-model="demandeurType"
                    :options="demandeurTypeOptions"
                    optionLabel="label"
                    optionValue="value"
                    :placeholder="'Type de DE'"
                    display="chip"
                  />
                </div>
              </div>
            </div>
            <div class="col-auto">
              <div class="d-grid gap-2">
                <a
                  href="#"
                  @click.prevent="mxSubmitQuery"
                  class="style_btn btn btn-primary"
                  id="button-query"
                  ><i class="bi bi-search"></i
                ></a>
              </div>
            </div>
            <div class="col-auto">
              <button
                type="button"
                @click.prevent="showAdvancedFilter = !showAdvancedFilter"
                class="style_btn btn btn-warning"
                id="button-query"
              >
                <i class="bi bi-filter"></i>
              </button>
            </div>
          </div>
        </form>
        <transition>
          <div class="border p-3 rounded shadow-0 mt-2" v-if="showAdvancedFilter">
            <div class="row p-fluid grid">
              <div class="field col-12 col-md-3 col-sm-12 mb-3">
                <label for="dateDeb">Date de début</label>
                <p-calendar
                  placeholder="Date de début"
                  v-model="dateDeb"
                  locale="fr"
                  :showIcon="true"
                  :manualInput="false"
                />
              </div>
              <div class="field col-12 col-md-3 col-sm-12 mb-3">
                <label for="dateFin">Date de fin</label>
                <p-calendar
                  placeholder="Date de fin"
                  class="flex-grow-1"
                  v-model="dateFin"
                  :showIcon="true"
                  :manualInput="false"
                />
              </div>
              <template v-for="(option, index) in filterOptions">
                <div
                  class="col-12 col-sm-6 col-md-3 col-sm-12 mb-3"
                  v-if="option.visible"
                  :key="index"
                >
                  <label :for="option.labelField">{{ option.label }}</label>

                  <AsyncMultiSelect
                    v-model="option.value"
                    :multiple="true"
                    :queryUrl="option.queryUrl"
                    :optionLabel="option.labelField"
                    :placeholder="option.label"
                    :emitObject="false"
                    :displayMode="option.displayMode"
                    :searchOptions="[option.labelField]"
                  />
                </div>
              </template>
            </div>
            <div class="d-flex justify-content-end">
              <!-- <PButton class="p-button-primary p-button- py-1 p-button-sm ms-1" @click.prevent.stop="resetFilterWithFromOffre">
            Matching avec les critères de l'offre
          </PButton> -->
              <PButton
                class="p-button-danger p-button- py-1 p-button-sm ms-1"
                @click.prevent.stop="reinitialiserFiltres"
              >
                Réinitialiser les filtres
              </PButton>
            </div>
          </div>
        </transition>
      </div>
      <p-divider />
      <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
        <div class="my-1 flex-fill">
          <h4>Liste des programmes bénéficiés</h4>
        </div>

        <div v-if="$can('export', 'Azoli')" class="btn-group ms-4">
          <PSplitButton
            label="Exporter"
            :model="exportActions"
            class="p-button-outlined p-button-primary"
          />
        </div>
        <div class="btn-group ms-4">
          <button
            @click.prevent="viewMode = 'table'"
            class="btn style_btn"
            :class="{
              'btn-light': viewMode == 'card',
              'btn-success': viewMode == 'table',
            }"
          >
            <i class="bi-table" />
          </button>
        </div>
      </div>
      <template v-if="!mxLoading">
        <div class="bg-white p-3 border">
          <div class="table-responsive">
            <b-table
              show-empty
              empty-text="Aucune donnée à afficher"
              :fields="tableFields"
              class="align-middle"
              striped
              select-mode="multi"
              ref="table"
              selectable
              @row-selected="onRowSelected"
              :items="tableData"
            >
              <template #head()="data">
                <span>{{ data.label }} </span>
              </template>
              <template #head(#)="data">
                <div class="form-check form-check-inline">
                  <input
                    @change="selectAll"
                    class="form-check-input"
                    type="checkbox"
                    :id="`${data.index}`"
                    name="disponible"
                    :value="false"
                  />
                </div>
              </template>
              <!-- A virtual column -->
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(id)="data"> PP000{{ data.item.id }} </template>

              <template #cell(#)="data">
                <div class="form-check form-check-inline">
                  <input
                    @input="selectionChanged($event, data)"
                    :checked="data.rowSelected"
                    class="form-check-input"
                    type="checkbox"
                    :id="`${data.index}`"
                    name="disponible"
                  />
                </div>
              </template>

              <template #cell(participant)="{ item: prog }">
                <span class="text-uppercase">{{
                  prog.participant.nom + " " + prog.participant.nom
                }}</span>
                <!-- <div>
                  <small class="text-muted">{{ prog.participant.npi }}</small>
                </div> -->
              </template>
              <template #cell(datePrevue)="{ item: prog }">
                <span class="text-uppercase">{{
                  $dayjs(prog.datePrevue).format("DD-MM-YYYY HH:mm")
                }}</span>
              </template>
              <template #cell(createdDate)="{ item: prog }">
                <span class="text-uppercase" v-if="prog.createdDate">{{
                  $dayjs(prog.createdDate).format
                }}</span>
              </template>
              <template #cell(statusParticipation)="{ item: prog }">
                <span
                  class="text-uppercase badge border-radius-5 py-1 px-2"
                  :class="getStatusColor(prog)"
                  >{{ prog.statusParticipation }}</span
                >
              </template>
              <template #cell(dateDebut)="{ item: prog }">
                {{ $dayjs(prog.dateDebut).format("DD-MM-YYYY") }}
              </template>
              <template #cell(dateFin)="{ item: prog }">
                {{ $dayjs(prog.dateFin).format("DD-MM-YYYY") }}
              </template>

              <template #cell(actions)="{ item: prog }">
                <div class="d-flex justify-content-end">
                  <a
                    class="btn btn-success rounded-0 btn-sm me-1"
                    @click="editProgrammeItem(prog)"
                    ><i class="bi bi-pencil-square"></i
                  ></a>
                  <a
                    class="btn btn-info rounded-0 btn-sm me-1"
                    title="Consulter et modifier"
                    @click.stop="showDemandeur(prog)"
                    ><i class="bi bi-info-square"></i
                  ></a>
                </div>
              </template>
              <!-- Optional default data cell scoped slot -->
              <template #cell()="data">
                <span>{{ data.value }}</span>
              </template>
            </b-table>
          </div>
          <div class="d-flex flex-wrap justify-content-between align-items-center">
            <p>
              Affichage de l’élément {{ mxPagination.page * mxPagination.rowsPerPage }} à
              {{ (mxPagination.page + 1) * mxPagination.rowsPerPage }} de
              {{ mxTotalCount }} éléments
            </p>
            <b-pagination
              v-model="mxPagination.page"
              :total-rows="mxTotalCount"
              :per-page="mxPagination.rowsPerPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="row mb-4" v-if="viewMode == 'card'">
          <div
            class="col-sm-4 col-lg-3 mb-3"
            v-for="i in mxPagination.rowsPerPage"
            :key="i"
          >
            <div class="style_profil_card p-3 pt-3">
              <div class="custom-skeleton p-3">
                <div
                  class="d-flex flex-column mb-2 align-items-center justify-content-center"
                >
                  <PSkeleton width="10rem" class="mb-2"></PSkeleton>
                  <PSkeleton shape="circle" size="6rem" class="mr-2 mt-2"></PSkeleton>
                  <div class="mt-4">
                    <PSkeleton width="12rem" class="mb-2"></PSkeleton>
                    <PSkeleton height=".5rem"></PSkeleton>
                  </div>
                </div>
                <div class="d-flex justify-content-between mt-3">
                  <PSkeleton width="2.5rem" height="2rem"></PSkeleton>
                  <PSkeleton width="2.5rem" height="2rem"></PSkeleton>
                  <PSkeleton width="2.5rem" height="2rem"></PSkeleton>
                  <PSkeleton width="2.5rem" height="2rem"></PSkeleton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <PDataTable
            :value="
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((e) => {
                e;
              })
            "
          >
            <PColumn
              v-for="f in tableFields"
              :key="f.key"
              :field="f.key"
              :header="f.label"
            >
              <template #body>
                <PSkeleton></PSkeleton>
              </template>
            </PColumn>
          </PDataTable>
        </div>
      </template>
      <div class="mb-4 bg-light" v-if="!mxLoading && mxFilteredItems.length == 0">
        <div
          style="min-height: 200px"
          class="no-content pa-4 d-flex flex-column justify-content-center align-items-center"
        >
          <span class="text-muted mb-3">Votre porte-feuille est vide</span>
          <a
            v-if="$can('create', 'Azoli')"
            @click.prevent="$router.push({ name: 'espace.conseiller.de.add' })"
            href=""
            class="style_btn btn btn-sm btn-outline-primary"
            >Ajouter un PPI</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import CAutoComplete from "../../../components/common/CAutoComplete.vue";
import AsyncMultiSelect from "../../../components/common/AsyncMultiSelect.vue";
import ProgrammeBeneficieEditor from "@/components/espace/common/prise-en-charge/editor/ProgrammeBeneficieEditor.vue";

export default {
  props: {
    extraQueryArg: {
      type: String,
      default: "",
    },
  },
  components: {
    AsyncMultiSelect,
    CAutoComplete,
    ProgrammeBeneficieEditor,
  },
  mixins: [paginatorMixin],
  data() {
    return {
      selected: [],
      search: "",
      activeProgramme: {},
      dateDeb: null,
      dateFin: null,
      showAdvancedFilter: false,
      viewMode: localStorage.getItem("viewMode") || "card", //card, table
      demandeurTypeOptions: [
        { value: null, label: "Tous" },
        { value: "PPI", label: "PPI" },
        { value: "CI", label: "CI" },
        { value: "INSTRUIT", label: "INSTRUIT" },
      ],
      demandeurType: null,
      sexeOptions: [
        { value: null, label: "Tous" },
        { value: "M", label: "Masculin" },
        { value: "F", label: "Féminin" },
      ],
      sexeDe: null,
      demandeurVulnerabiliteOptions: [
        { value: null, label: "Tous" },
        { value: true, label: "VULNERABLE" },
        { value: false, label: "NON VULNERABLE" },
      ],
      demandeurVulnerabilite: null,
      statusParticipationOptions: [
        { value: null, label: "Tous" },
        { value: true, label: "VULNERABLE" },
        { value: false, label: "NON VULNERABLE" },
      ],
      statusParticipation: null,
      exportActions: [
        {
          label: "Excel",
          icon: "pi pi-file-excel",
          command: () => {
            let params = this.mxGetQueryBuilder();
            let route = this.$router.resolve({
              name: "common.export",
              query: {
                dataGetter: this.mxDataGetter,
                fetcherMethod: this.mxFetcherMethod,
                queryParams: params,
                fileName: "Liste des bénéficiaires Azoli.xlsx",
              },
            });
            window.open(route.href, "_blank");
            // this.mxExportToExcel().then(items => {
            //   const data = XLSX.utils.json_to_sheet(items.map(el => ({
            //     ...el,
            //     ...el.azoli,
            //     commune: el.commune.name,
            //     departement: el.departement.name,
            //     village: el.ville.name,
            //     arrondissement: el.arrondissement.name,
            //   })))
            //   const wb = XLSX.utils.book_new()
            //   XLSX.utils.book_append_sheet(wb, data, 'data')
            //   XLSX.writeFile(wb,'Liste des bénéficiaires Azoli.xlsx')
            // })
          },
        },
      ],
      searchFields: ["participant.nomPrenom"],
      filterOptions: [
        {
          column: "propgramme.id",
          value: [],
          label: "Nom du programme",
          dropdown: true,
          labelField: "nom",
          displayMode: "classic",
          valueKey: "id",
          queryUrl: "programmes",
          visible: true,
        },
        {
          column: "participant.departement.id",
          value: "",
          labelField: "nom",
          label: "Départements",
          queryUrl: "departements",
          displayMode: "mega-menu",
          multiple: true,
          valueKey: "id",
          dropdown: true,
          visible: true,
        },
        {
          column: "participant.commune.id",
          value: "",
          labelField: "nom",
          multiple: true,
          valueKey: "id",
          label: "Communes",
          displayMode: "classic",
          queryUrl: "communes",
          dropdown: true,
          visible: true,
        },
        {
          column: "participant.arrondissement.id",
          value: "",
          labelField: "nom",
          label: "Arrondissements",
          multiple: true,
          valueKey: "id",
          queryUrl: "arrondissements",
          displayMode: "classic",
          dropdown: true,
          visible: true,
        },
        {
          column: "participant.ville.id",
          value: "",
          labelField: "nom",
          multiple: true,
          label: "Village/Quartier(s)",
          valueKey: "id",
          displayMode: "classic",
          queryUrl: "villes",
          dropdown: true,
          visible: true,
        },
        // {
        //   column: "participant.ville.id",
        //   value: "",
        //   labelField: "nom",
        //   multiple: true,
        //   label: "Village/Quartier(s)",
        //   valueKey: "id",
        //   displayMode: "classic",
        //   queryUrl: "villes",
        //   dropdown: true,
        //   visible: true,
        // },
      ],
    };
  },
  created() {
    this.queryStatistics();
  },
  watch: {
    viewMode() {
      localStorage.setItem("viewMode", this.viewMode);
    },
  },
  computed: {
    ...mapGetters({}),
    tableFields() {
      return [
        "#",
        // 'index',
        // { key: "id", label: "Ref" },
        { key: "participant", label: "Bénéficiaire" },
        { key: "participant.sexe", label: "Sexe" },
        { key: "participant.phone", label: "Phone" },
        { key: "participant.departement.nom", label: "Département" },
        { key: "participant.commune.nom", label: "Commune" },
        { key: "participant", label: "Bénéficiaire" },
        { key: "programme.nom", label: "Programme" },
        { key: "dateDebut", label: "Date Début" },
        { key: "dateFin", label: "Date Fin" },
        { key: "statusParticipation", label: "Statut" },
        "actions",
      ];
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
  },
  methods: {
    ...mapActions({
      updateParticipationProgramme: "participationProgramme/updateParticipationProgramme",
    }),
    editProgrammeItem(prog) {
      this.activeProgramme = { ...prog };
      this.$refs.programmeBeneficieEditor.show();
    },
    async saveProgrammeItem(prog) {
      await this.$confirm.require({
        group: "confirmDialog",
        message: `Voulez-vous vraiment appliquer les modifications effectuées`,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          if (prog.modeStatut == "AUTO") {
            this.$toast.error(
              "Vous ne pouvez pas modifier un programme dont le statut a été automatiquement marqué par la plateforme!",
              {
                position: "top-right",
                duration: 10000,
              }
            );
            return;
          }
          this.updateParticipationProgramme({
            ...prog,
          })
            .then(() => {
              this.queryStatistics();
              this.$toast.success("Opération effectuée avec succès!", {
                position: "top-right",
                duration: 10000,
              });
            })
            .catch((err) => {
              this.$toast.error("Une erreur s'est produite lors de l'opération!", {
                position: "top-right",
                duration: 10000,
              });
            });
        },
      });
    },
    getStatusColor(prog) {
      let bg = "event-box-pending text-white";

      if (prog.statusParticipation == "ACCORDE") {
        bg = "event-box-done text-white";
      } else if (prog.statusParticipation == "PRESCRIT") {
        bg = "event-box-missed text-white";
      } else if (prog.statusParticipation == "SUSPENDU") {
        bg = "event-box-reported text-white";
      } else if (prog.statusParticipation == "ANNULE") {
        bg = "bg-warning text-dark";
      }
      return bg;
    },
    reinitialiserFiltres() {
      this.filterOptions.forEach((option) => (option.value = []));
      this.demandeurType = null;
      this.sexeDe = null;
      this.demandeurVulnerabilite = null;
    },
    statisticBlockSelected(status) {
      this.mxSetOptionValue("status", status, true);
    },
    queryStatistics() {
      this.mxInitializePaginator({
        queryModel: "participationProgramme",
        search: this.search,
        fetcherMethod: "participationProgramme/fetchParticipationProgrammes",
        dataGetter: "participationProgramme/participationProgrammes",
        paginationGetter: "participationProgramme/pagination",
        autoSubmitQuery: false,
        pagination: {
          sortBy: "id",
          descending: false,
          page: 0,
          rowsPerPage: 10,
          sortingParam: `sort=id,desc`,
        },
        filterOptions: this.filterOptions,
        extraQueryArgsParamBuilder: () => {
          const dateDeb = (this.dateDeb || new Date("2021-12-31"))
            .toISOString()
            .substring(0, 10);
          const dateFin = (this.dateFin || new Date("2080-12-31"))
            .toISOString()
            .substring(0, 10);

          let paramPlus = this.mxAddQueryParam(
            "participant.categorieDemandeur",
            this.demandeurType
          );
          paramPlus = this.mxAddQueryParam(
            "participant.estVulnerable",
            this.demandeurVulnerabilite,
            paramPlus
          );
          paramPlus = this.mxAddQueryParam("participant.sexe", this.sexeDe, paramPlus);

          let params = `createdDate=${dateDeb}&createdDate=${dateFin}&${this.extraQueryArg}&${paramPlus}`;
          return params;
        },
        searchFields: this.searchFields,
      });
    },
    selectionChanged(evt, data) {
      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
  },
};
</script>

<style></style>
