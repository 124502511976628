/* eslint-disable vue/no-unused-components */
<template>
  <div class="container-fluid">
    <div class="statistic-box py-2 mt-4 mb-3">
      <div class="row">
        <div class="col" v-for="(st, i) in statInfo" :key="i">
          <div class="card border">
            <div class="card-body">
              <div class="card-title">
                <i :class="st.icon + ' me-2 fas-1'"></i>
                {{ st.libelle }}
              </div>
              <p class="card-text display-4 fw-bold mb-0">{{ st.value }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container- mt-1">
      <p-progress-bar v-if="mxLoading" style="height: 0.33em" mode="indeterminate" />
      <div class="bg-white p-2 rounded-4">
        <form class="bg-" method="post" accept-charset="utf-8" action="">
          <div class="row py-0 p-fluid grid formgrid">
            <div class="col field">
              <form class="d-flex my-1">
                <div class="input-group me-4">
                  <div class="p-inputgroup">
                    <!-- <span class="p-input-icon-left">
                    <i class="pi pi-search" />
                  </span> -->
                    <PInputText
                      type="text"
                      v-model="mxSearch"
                      :placeholder="'Rechercher par nom et prénom'"
                    />

                    <!-- <p-dropdown
                      class="flex-grow- me-2"
                      v-model="typeConsigne"
                      :options="typeConsigneOptions"
                      optionLabel="label"
                      optionValue="value"
                      :placeholder="'Type consigne'"
                      display="chip"
                    /> -->
                    <p-dropdown
                      class="flex-grow-1 me-2"
                      v-model="statutConsigne"
                      :options="statutConsigneOptions"
                      optionLabel="label"
                      optionValue="value"
                      :placeholder="'Statut consigne'"
                      display="chip"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div class="col-12 col-sm-6 col-md-3 mb-4">
              <AsyncMultiSelect
                v-model="typeConsigne"
                :queryUrl="'typeConsignes'"
                :optionLabel="'libelle'"
                :optionValue="'id'"
                :placeholder="'Type de consigne'"
                :emitObject="false"
                :displayMode="'classic'"
                :searchOptions="['libelle']"
              />
            </div>
            <div class="col-auto">
              <div class="d-grid gap-2">
                <a
                  href="#"
                  @click.prevent="submitQuery"
                  class="style_btn btn btn-primary"
                  id="button-query"
                  ><i class="bi bi-search"></i
                ></a>
              </div>
            </div>
            <div class="col-auto">
              <button
                type="button"
                @click.prevent="showAdvancedFilter = !showAdvancedFilter"
                class="style_btn btn btn-warning"
                id="button-query"
              >
                <i class="bi bi-filter"></i>
              </button>
            </div>
          </div>
        </form>

        <transition>
          <div class="card mx- border-radius-5 mt-2 hadow-5" v-if="showAdvancedFilter">
            <div class="row p-fluid p-3 grid">
              <div class="field col-12 col-md-3">
                <label for="basic">Date de début</label>
                <p-calendar
                  placeholder="Date de début"
                  v-model="dateDeb"
                  locale="fr"
                  :showIcon="true"
                  :manualInput="false"
                />
              </div>
              <div class="field col-12 col-md-3">
                <label for="basic">Date de fin</label>
                <p-calendar
                  placeholder="Date de fin"
                  class="flex-grow-1"
                  v-model="dateFin"
                  :showIcon="true"
                  :manualInput="false"
                />
              </div>
              <template v-for="(option, index) in filterOptions">
                <div
                  class="col-12 col-sm-6 col-md-3 mb-4"
                  v-if="option.visible"
                  :key="index"
                >
                  <label :for="option.label">{{ option.label }}</label>

                  <AsyncMultiSelect
                    :id="option.label"
                    v-model="option.value"
                    :multiple="true"
                    :queryUrl="option.queryUrl"
                    :optionLabel="option.labelField"
                    :placeholder="option.label"
                    :emitObject="false"
                    v-if="option.queryUrl"
                    :displayMode="option.displayMode"
                    :searchOptions="[option.labelField]"
                  />
                  <p-dropdown
                    :id="option.label"
                    class="flex-grow-1 me-2"
                    v-model="option.value"
                    :options="option.items"
                    v-else
                    optionLabel="label"
                    optionValue="value"
                    :placeholder="option.label"
                    display="chip"
                  />
                </div>
              </template>
            </div>
          </div>
        </transition>
      </div>
      <p-divider />
      <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
        <div class="my-1 flex-fill">
          <h4>Liste des consignes</h4>
        </div>
        
        <div v-if="$can('export', 'Azoli')" class="btn-group ms-4">
          <PSplitButton
            label="Exporter"
            :model="exportActions"
            class="p-button-outlined p-button-primary"
          />
        </div>
        <div class="btn-group ms-4">
          <button
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Affichage mode tableau"
            @click.prevent="viewMode = 'card'"
            class="btn style_btn btn-md"
            :class="{
              'btn-success': viewMode == 'card',
              'btn-light': viewMode == 'table',
            }"
          >
            <i class="bi-grid" />
          </button>
          <button
            @click.prevent="viewMode = 'table'"
            class="btn style_btn"
            :class="{
              'btn-light': viewMode == 'card',
              'btn-success': viewMode == 'table',
            }"
          >
            <i class="bi-table" />
          </button>
        </div>
      </div>
      <template v-if="!mxLoading">
        <div class="bg-white p-">
          <Consignes v-model="tableData" />

          <div class="d-flex flex-wrap justify-content-between align-items-center">
            <p>
              Affichage de l’élément {{ mxPagination.page * mxPagination.rowsPerPage }} à
              {{ (mxPagination.page + 1) * mxPagination.rowsPerPage }} de
              {{ mxTotalCount }} éléments
            </p>
            <b-pagination
              v-model="mxPagination.page"
              :total-rows="mxTotalCount"
              :per-page="mxPagination.rowsPerPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
      </template>
      <template v-else>
        <div>
          <PDataTable :value="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]">
            <PColumn
              v-for="f in tableFields"
              :key="f.key"
              :field="f.key"
              :header="f.label"
            >
              <template #body>
                <PSkeleton></PSkeleton>
              </template>
            </PColumn>
          </PDataTable>
        </div>
      </template>
      <div class="mb-4 bg-light" v-if="!mxLoading && mxFilteredItems.length == 0">
        <div
          style="min-height: 200px"
          class="no-content pa-4 d-flex flex-column justify-content-center align-items-center"
        >
          <span class="text-muted mb-3">Votre porte-feuille est vide</span>
          <a
            v-if="$can('create', 'Azoli')"
            @click.prevent="$router.push({ name: 'espace.conseiller.de.add' })"
            href=""
            class="style_btn btn btn-sm btn-outline-primary"
            >Ajouter un PPI</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { paginatorMixin } from "@/mixins/paginator-mixin";
import Consignes from "../../../components/espace/common/prise-en-charge/ConsignesReadOnly.vue";
import CAutoComplete from "../../../components/common/CAutoComplete.vue";
import AsyncMultiSelect from "../../../components/common/AsyncMultiSelect.vue";

export default {
  props: {
    extraQueryArg: {
      type: String,
      default: "",
    },
  },
  components: {
    CAutoComplete,
    Consignes,
    AsyncMultiSelect,
  },
  mixins: [paginatorMixin],
  data() {
    return {
      selected: [],
      search: "",
      dateDeb: null,
      dateFin: null,
      showAdvancedFilter: false,
      statutConsigne: null,
      stat: {},
      statutConsigneOptions: [
        { value: null, label: "Tous" },
        { value: "NON_REALISE", label: "NON_REALISE" },
        { value: "EN_COURS", label: "EN COURS" },
        { value: "TERMINE", label: "TERMINE" },
        { value: "INVALIDE", label: "TERMINE" },
      ],
      typeConsigne: null,
      typeConsigneOptions: [
        { value: null, label: "Tous" },
        //Add through api call
      ],
      viewMode: localStorage.getItem("viewMode") || "card", //card, table
      exportActions: [
        {
          label: "Excel",
          icon: "pi pi-file-excel",
          command: () => {
            let params = this.mxGetQueryBuilder();
            let route = this.$router.resolve({
              name: "common.export",
              query: {
                dataGetter: this.mxDataGetter,
                fetcherMethod: this.mxFetcherMethod,
                queryParams: params,
                fileName: "Liste des bénéficiaires Azoli.xlsx",
              },
            });
            window.open(route.href, "_blank");
            // this.mxExportToExcel().then(items => {
            //   const data = XLSX.utils.json_to_sheet(items.map(el => ({
            //     ...el,
            //     ...el.azoli,
            //     commune: el.commune.name,
            //     departement: el.departement.name,
            //     village: el.ville.name,
            //     arrondissement: el.arrondissement.name,
            //   })))
            //   const wb = XLSX.utils.book_new()
            //   XLSX.utils.book_append_sheet(wb, data, 'data')
            //   XLSX.writeFile(wb,'Liste des bénéficiaires Azoli.xlsx')
            // })
          },
        },
      ],
      searchFields: ["demandeur.nomPrenom"],
      searchFieldsOptions: [
        {
          label: "Nom(s) et Prénom(s)",
          value: "demandeur.nomPrenom",
          visible: true,
        },
      ],
      filterOptions: [
        {
          column: "typeCheminEmploi",
          value: "",
          key: "typeCheminEmploi",
          label: "Type d'emploi",
          valueKey: "value",
          dropdown: true,
          multiple: false,
          visible: true,
          items: [
            { value: null, label: "Tous" },
            { value: "SALARIE", label: "SALARIE" },
            { value: "INDEPENDANT", label: "INDEPENDANT" },
          ],
        },
        {
          column: "demandeur.sexe",
          value: "",
          label: "Sexe",
          valueKey: "value",
          dropdown: true,
          multiple: false,
          visible: true,
          items: [
            { value: null, label: "Tous" },
            { value: "M", label: "M" },
            { value: "F", label: "F" },
          ],
        },
        {
          column: "demandeur.categorieDemandeur",
          value: "",
          label: "Type de DE",
          valueKey: "value",
          dropdown: true,
          multiple: false,
          visible: true,
          items: [
            { value: null, label: "Tous" },
            { value: "PPI", label: "PPI" },
            { value: "CI", label: "CI" },
            { value: "INSTRUIT", label: "INSTRUIT" },
          ],
        },
        {
          column: "demandeur.metaInfo.estSuiviParId",
          value: "",
          label: "Suivi par",
          labelField: "nomPrenom",
          valueKey: "id",
          displayMode: "classic",
          queryUrl: "agents",
          dropdown: true,
          visible: true,
          multiple: true,
        },
      ],
    };
  },
  created() {
    this.loadData();
  },
  watch: {
    viewMode() {
      localStorage.setItem("viewMode", this.viewMode);
    },
  },
  computed: {
    ...mapGetters({
      pagination: "demandeur/pagination",
    }),
    tableFields() {
      return [
        "#",
        // 'index',
        { key: "id", label: "Ref" },
        { key: "participant", label: "Bénéficiaire" },
        { key: "programme.nom", label: "Programme" },
        { key: "dateDebut", label: "Date Début" },
        { key: "dateFin", label: "Date Fin" },
        { key: "statusParticipation", label: "Statut" },
        "actions",
      ];
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
    statInfo() {
      function transformCounts(counts) {
        return Object.keys(counts).map((key) => {
          const libelle = key
            .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between camel case words
            .replace(/Count$/, "") // Remove the 'Count' suffix
            .toUpperCase(); // Convert to uppercase (optional)

          return {
            libelle,
            value: counts[key],
          };
        });
      }

      return transformCounts(this.stat || {}).map((item) => {
        let icon = "pi pi-question-circle";
        const lowerLibelle = item.libelle.toLowerCase();
        if (lowerLibelle.includes("en cours")) icon = "pi pi-spinner text-primary";
        if (lowerLibelle.includes("termine")) icon = "pi pi-check-circle text-success";
        if (lowerLibelle.includes("non realise"))
          icon = "pi pi-times-circle text-warning";
        if (lowerLibelle.includes("invalide")) icon = "pi pi-ban text-danger";
        return { ...item, icon: icon };
      });
    },
  },
  methods: {
    ...mapActions({
      fetchConsigneCount: "statistic/fetchConsigneCount",
    }),
    getStatusColor(activite) {
      let bg = "event-box-pending text-white";

      if (activite.statusActivite == "ACTIF") {
        bg = "event-box-done text-white";
      } else if (activite.statusActivite == "ANNULE") {
        bg = "event-box-missed text-white";
      } else if (activite.statusActivite == "SUSPENDU") {
        bg = "event-box-reported text-white";
      }
      return bg;
    },
    statisticBlockSelected(status) {
      this.mxSetOptionValue("status", status, true);
    },
    queryStatistics() {
      this.mxInitializePaginator({
        queryModel: "consigne",
        search: this.search,
        fetcherMethod: "consigne/fetchConsignes",
        dataGetter: "consigne/consignes",
        paginationGetter: "consigne/pagination",
        autoSubmitQuery: false,
        pagination: {
          sortBy: "id",
          descending: false,
          page: 0,
          rowsPerPage: 10,
          sortingParam: `sort=id,desc`,
        },
        filterOptions: this.filterOptions,
        extraQueryArgsParamBuilder: () => {
          const dateDeb = (this.dateDeb || new Date("2021-12-31"))
            .toISOString()
            .substring(0, 10);
          const dateFin = (this.dateFin || new Date("2080-12-31"))
            .toISOString()
            .substring(0, 10);

          let paramPlus = this.mxAddQueryParam("etat", this.statutPrescription);

          paramPlus = this.mxAddQueryParam(
            "typeConsigne.id",
            this.typeConsigne != null && this.typeConsigne.length != 0
              ? this.typeConsigne[0].id
              : null,
            paramPlus
          );

          const params = `createdDate=${dateDeb}&createdDate=${dateFin}&${this.extraQueryArg}&${paramPlus}`;
          return params;
        },
        searchFields: this.searchFields,
      });
    },
    submitQuery() {
      this.loadData();
    },
    loadData() {
      this.queryStatistics();
      const queryParam = this.mxBuildQueryParams();
      this.fetchConsigneCount({
        extraQueryArg: queryParam,
      }).then((data) => {
        this.stat = data;
      });
    },
    selectionChanged(evt, data) {
      console.log(evt.target.checked, data);

      if (evt.target.checked) {
        this.$refs.table.selectRow(data.index);
      } else {
        this.$refs.table.unselectRow(data.index);
      }
    },
    onRowSelected(items) {
      this.selected = items;
    },
    selectAll(evt) {
      if (evt.target.checked) {
        this.$refs.table.selectAllRows();
      } else {
        this.$refs.table.clearSelected();
      }
    },
  },
};
</script>

<style scoped>
.statistic-box {
  /* background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem; */
}

.statistic-box .card {
  border: none;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease-in-out;
  /* background: linear-gradient(to right, #ffffff, #f0f8ff); */
}

.statistic-box .card:hover {
  transform: translateY(-0.25rem);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
}

.statistic-box .card-body {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.statistic-box .card-title {
  font-size: 1rem;
  /* font-weight: 500; */
  /* color: #6c757d; */
  margin-bottom: 0.5rem;
}

.statistic-box .card-text {
  font-size: 2rem;
  /* font-weight: 700; */
  /* color: #28a745; */
}

.statistic-box .card-title {
  font-size: 1.1rem;
  /* font-weight: 600; */
  /* color: #495057; */
  margin-bottom: 1.2rem;
}
</style>
