<template>
  <div class="containe">
    <div class="row">
      <div class="col">
        <div class="card">
          <PDataTable
            class="shadow-none"
            :value="consignes"
            stripedRows
            editMode="row"
            dataKey="id"
            :editingRows.sync="editingRows"
          >
            <!-- <PColumn :expander="true" /> -->
            <PColumn field="demandeur" header="Demandeur">
              <template #body="slotProps">
                {{ slotProps.data.demandeur.nomPrenom }}
              </template>
            </PColumn>
            <PColumn field="typeConsigne" :styles="{ width: colWidth }" header="Consigne">
              <template #body="slotProps">
                <a href="" @click.prevent="toggleExpandRow(slotProps.data)">
                  {{ slotProps.data.typeConsigne.libelle }}
                </a>
              </template>
            </PColumn>
            <!-- <PColumn field="contenu" :styles="{ width: colWidth }" header="Contenu">
                <template #body="slotProps">
                    <PBadge v-if="slotProps.data.contenus.length>0" :value="slotProps.data.contenus.length" severity="primary"></PBadge>
                </template>
              </PColumn> -->
            <PColumn field="dateDebut" :styles="{ width: colWidth }" header="Date Début">
              <template #editor="slotProps">
                <PInputText v-model="slotProps.data[slotProps.column.field]" />
              </template>
              <template #body="slotProps">
                <span v-if="slotProps.data[slotProps.column.field]">{{
                  slotProps.data[slotProps.column.field] | dayjs("DD-MM-YYYY HH:mm")
                }}</span>
                <span v-else>NA</span>
              </template>
            </PColumn>
            <PColumn field="dateFin" :styles="{ width: colWidth }" header="Date Fin">
              <template #editor="slotProps">
                <PInputText v-model="slotProps.data[slotProps.column.field]" />
              </template>
              <template #body="slotProps">
                <span v-if="slotProps.data[slotProps.column.field]">{{
                  slotProps.data[slotProps.column.field] | dayjs("DD-MM-YYYY HH:mm")
                }}</span>
                <span v-else>NA</span>
              </template>
            </PColumn>
            <PColumn field="etat" header="Etat">
              <template #body="slotProps">
                <!-- {{ slotProps.data.etat || 'N/A' }} -->
                <PBadge
                  :value="slotProps.data.etat"
                  :severite="slotProps.data.etat == 'TERMINE' ? '' : 'info'"
                />
              </template>
            </PColumn>
            <PColumn field="fullFileUrl" header="Pièce jointe">
              <template #body="slotProps">
                <a
                  v-if="slotProps.data.fullFileUrl"
                  href="#"
                  @click.prevent="downloadFile({ url: slotProps.data.fullFileUrl })"
                  ><i
                    class="text-primary bi bi-file-earmark-arrow-down"
                    style="font-size: 1.6rem"
                  ></i
                ></a>
                <!-- <PBadge :value="slotProps.data.etat" :severite="slotProps.data.etat=='TERMINE'? '':'info'" /> -->
              </template>
            </PColumn>
            <PColumn
              field="observation"
              :styles="{ width: colWidth }"
              header="Observation"
            >
              <template #editor="slotProps">
                <PInputText v-model="slotProps.data[slotProps.column.field]" />
              </template>
            </PColumn>
            <PColumn v-if="false" :styles="{ width: colWidth }" header="Action">
              <template #body="slotProps">
                <div class="d-flex justify-space-between">
                  <PButton
                    :disabled="readOnly"
                    @click.prevent="$refs.consigneEditor.show()"
                    icon="pi pi-pencil"
                    class="p-button-rounded p-button-primary"
                    @click="editConsigneItem(slotProps.data)"
                  />
                  <!-- <PButton icon="pi pi-plus" class="p-button-rounded p-button-outlined p-button-primary" @click="addContenuConsigneItem(slotProps.data)" /> -->
                  <PButton
                    :disabled="readOnly"
                    icon="pi pi-trash"
                    class="p-button-rounded p-button-warning"
                    @click="confirmDeleteItem(slotProps.data)"
                  />
                </div>
              </template>
            </PColumn>
          </PDataTable>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConsigneEditor from "./editor/ConsigneEditor.vue";
import { mapActions } from "vuex";

export default {
  props: {
    value: {
      type: Array,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ConsigneEditor,
  },
  data() {
    return {
      display: false,
      consignes: [...this.value],
      expandedRows: [],
      editingRows: [],
      activeConsigne: null,
      activeContenu: null,
      activeIndex: null,
    };
  },
  computed: {
    colWidth() {
      return "100%";
    },
    etats() {
      return [
        {
          value: "NON_REALISE",
          label: "Non Réalisé",
        },
        {
          value: "EN_COURS",
          label: "En cours",
        },
        {
          value: "TERMINE",
          label: "Terminé",
        },
      ];
    },
    emptyContenuConsigne() {
      return {
        demandeur: null,
        observation: null,
        consigne: null,
        dateDebut: null,
        dateFin: null,
        etat: null,
      };
    },
  },
  methods: {
    ...mapActions({}),
    onRowExpand() {},
    onRowCollapse() {},
    toggleExpandRow(item) {
      console.log(this.expandedRows);
      const index = this.expandedRows.indexOf((el) => el.id == item.id);
      if (index != -1) {
        this.expandedRows.push(item);
      } else {
        this.expandedRows = this.expandedRows.filter((el) => el.id != item.id);
      }
    },
  },
};
</script>
